import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "app/shared/services/app.service";
import { AuthGuard } from "app/shared/services/auth/auth.guard";
import { LayoutService } from "app/shared/services/layout.service";
import { FacilitesServiceService } from "app/views/others/induster-pages/IndusterServices/facilites-service.service";
import { LocationMenuServiceService } from "app/views/others/induster-pages/IndusterServices/location-menu-service.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.template.html",
})
export class SidenavComponent implements OnInit {
  @Input("items") public menuItems: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  @Input("iconMenuTitle") public iconTypeMenuTitle: string;
  @Output() helpClicked = new EventEmitter<void>();

  public layoutConf: any = {};

  constructor(
    private router: Router,
    private layout: LayoutService,
    private _FaciltyServ: FacilitesServiceService,
    private authGuard: AuthGuard,
    private appService: AppService,
    private _LocationServ: LocationMenuServiceService

  ) {
    this.layoutConf = this.layout.layoutConf;
  }

  get currentRoute(): string {
    return this.router.url;
  }

  modules;

  ngOnInit() {
    this.getAllFacilities(this.menuItems);
    this._LocationServ.getAppModules().subscribe((res: any) => {
      this.modules = res;
    });
  }

  isInRole(dataItem){
    const roles = dataItem?.roles;
    const moduleName = dataItem?.module || dataItem?.moduleName;

    if(moduleName != null && moduleName != '' && this.modules){
      let module = this.modules?.find(x => x.module?.toLowerCase() == moduleName?.toLowerCase());
      if(module == null){
        return false;
      }
    }

    //if role array is empty, then assume user has access
    if(roles.length == 0){
      return true;
    }

    let hasRole = false;
    roles?.forEach(role => {
      if(this.authGuard.isInRole(role)){
        hasRole = true;
      }
    });
    return hasRole;
  }

  getAllFacilities(menuItems) {
    this._FaciltyServ
      .getAllFacilities()
      .pipe(take(1))
      .subscribe((facilities) => {
        if (Array.isArray(facilities)) {
          if (facilities.length === 1) {
            this.menuItems = menuItems.filter(
              (item) => item.icon !== "factory"
            );
          }
        }
      });
  }

  openHelp() {
    this.helpClicked.emit();
  }
}
