import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  private _isMobile = new Subject();
  public isMobile : boolean;
  public screenWidth: string;

  constructor() {
    this.checkWidth();
  }

  private onMobileChange(status: boolean) {
    this.isMobile = status;
    this._isMobile.next(status);
  }

  getMobileStatus(): Observable<any> {
    return this._isMobile.asObservable();
  }

  checkWidth() {
    var width = window.innerWidth;
    if (width <= 1024) {
      this.screenWidth = 'sm';
      this.onMobileChange(true);
    }  else {
      this.screenWidth = 'lg';
      this.onMobileChange(false);
    }
  }
}
