import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';


import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { rootRouterConfig } from './app.routing';
import { SharedModule } from './shared/shared.module';

/* NgRx */
import { DatePipe, registerLocaleData } from '@angular/common';
import { MATERIAL_SANITY_CHECKS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { BlockUIModule } from 'ng-block-ui';
import { environment } from '../environments/environment';
import { ApiInterceptorService } from './api-interceptor.service';
import { AppConfig } from './app-config.module';
import { CoreModule } from './views/core/core.module';
import { coreReducer } from './views/core/state/core.reducer';
import { CommonServiceService } from './views/others/induster-pages/IndusterServices/common-service.service';
import { FacilitesServiceService } from './views/others/induster-pages/IndusterServices/facilites-service.service';
import { PurchaseOrderServiceService } from './views/others/induster-pages/IndusterServices/purchase-order-service.service';
import { SupplierServiceService } from './views/others/induster-pages/IndusterServices/supplier-service.service';
import { RefdataEffects } from './views/refdata/state/refdata.effects';
import { refdataReducer } from './views/refdata/state/refdata.reducer';
import { UserEffects } from './views/user/state/user.effects';
import { userReducer } from './views/user/state/user.reducer';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomSnackbarComponent } from './shared/components/custom-snackbar/custom-snackbar.component';



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: false,
  animation: {
    enterDuration: 300,
    exitDuration: 100
  }
};


import localePt from '@angular/common/locales/pt';
import "@progress/kendo-angular-intl/locales/pt/all";
import { ShipmentService } from './views/others/induster-pages/IndusterServices/shipment.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

registerLocaleData(localePt, 'pt-PT');

/*
//TD: Not in use!?
const JWT_Module_Options: JwtModuleOptions = {
  config: {
  }
};
*/

// Add this function
export function initConfig(config: AppConfig){
  return () => config.load()
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
//    NoopAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(rootRouterConfig, {useHash: false}),
    StoreModule.forRoot({core: coreReducer, refdata: refdataReducer, user: userReducer}),
    EffectsModule.forRoot([RefdataEffects, UserEffects]),
    StoreDevtoolsModule.instrument({
      name: 'AMF DevTools',
      maxAge: 25,
      logOnly: environment.production
    }),
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    CoreModule,
    BlockUIModule.forRoot(),
    GridModule,
    InputsModule,
    LayoutModule,
    ToolBarModule,
    ListViewModule,
    MatDialogModule,
    ScrollViewModule,
    NavigationModule,
    DialogsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IndicatorsModule
  ],
  declarations: [AppComponent, CustomSnackbarComponent],
  providers: [
    {provide: MATERIAL_SANITY_CHECKS, useValue: false},
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true},
    {provide: LOCALE_ID, useValue: "pt-PT" },
    AppConfig,
    DatePipe,
    { provide: APP_INITIALIZER,
      useFactory: initConfig, // And use it here
      deps: [AppConfig],
      multi: true } ,
    HttpClientModule,CommonServiceService,
    SupplierServiceService,FacilitesServiceService,PurchaseOrderServiceService,ShipmentService,

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
