import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, exhaustMap, map, mergeMap, tap, take} from 'rxjs/operators';

/* NgRx */
import {Action} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as refdataActions from './refdata.actions';
import {RefdataService} from '../refdata.service';
import {IRefdataGetRequestModel} from '../models/store/i-refdata.get.request.model';
import {IRefdataGetResponseModel} from '../models/store/i-refdata.get.response.model';
import {ICountryRefdataModel} from '../models/i-country-refdata.model';
import {IExchangeRateRefdataModel} from '../models/i-exchange-rate-refdata.model';
import {CacheService} from '../../../shared/services/CacheService';

@Injectable()
export class RefdataEffects {

  
  getRefdataDashboard$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetRefdata),
    map((action: refdataActions.GetRefdata) => action.payload),
    mergeMap((request: number) =>

    this.cacheService.get('SAR.RefData.' + request, this.srvRefdata.getByRefdataTypeId<IRefdataGetResponseModel>(request).pipe(
        map((response: IRefdataGetResponseModel) => (new refdataActions.GetRefdataSuccess(response))),
        catchError(err => of(new refdataActions.GetRefdataError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    ))
  ));

  
  getCountriesRefdata$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetCountries),
    mergeMap(() =>
    this.cacheService.get('SAR.Countries', this.srvRefdata.getCountriesRefdata<ICountryRefdataModel[]>().pipe(
        map((response: ICountryRefdataModel[]) => (new refdataActions.GetCountriesSuccess(response))),
        catchError(err => of(new refdataActions.GetCountriesError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    ))
  ));

  
  getExchangeRatesRefdata$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(refdataActions.RefdataActionTypes.GetExchangeRates),
    mergeMap(() =>
    this.cacheService.get('SAR.ExchangeRates', this.srvRefdata.getExchangeRatesRefdata<IExchangeRateRefdataModel[]>().pipe(
        map((response: IExchangeRateRefdataModel[]) => (new refdataActions.GetExchangeRatesSuccess(response))),
        catchError(err => of(new refdataActions.GetExchangeRatesError(err),
          new refdataActions.ShowGlobalFeedback(err)))
      )
    ))
  ));

  constructor(private actions$: Actions,
              private srvRefdata: RefdataService,
              private cacheService: CacheService) {
  }
}
