import {Component, OnInit, AfterViewInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot, RouterOutlet, RouterLink, ChildrenOutletContexts} from '@angular/router';

import {RoutePartsService} from './shared/services/route-parts.service';
import {ThemeService} from './shared/services/theme.service';

import {select, Store} from '@ngrx/store';
import * as fromCore from './views/core/state';
import * as fromRefdata from './views/refdata/state';

import {filter, takeWhile} from 'rxjs/operators';
import {IFeedbackModel} from './views/core/models/i-feedback.model';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { slideInAnimation } from './route-animation';
import { ResponsiveService } from './shared/services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
//  imports: [RouterLink, RouterOutlet],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'AppMyShopfloor';
  pageTitle = '';
  feedbacks$: Observable<IFeedbackModel[]>;
  isComponentActive: true;
  isMobile = false;

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private store: Store<fromRefdata.State>,
    public snackBar: MatSnackBar,
    private contexts: ChildrenOutletContexts,
    private responsiveService: ResponsiveService
  ) {
  }


  ngOnInit() {
    this.changePageTitle();
    this.feedbacks$ = this.store.pipe(select(fromCore.selectors.getFeedbacks));
    this.responsiveService.getMobileStatus().subscribe( isMobile =>{
      if(isMobile){
        console.log('Mobile device detected');
        this.isMobile = true;
      }
      else{
        console.log('Desktop detected');
        this.isMobile = false;
      }
    });
    this.onResize();
  }

  onResize(){
    this.responsiveService.checkWidth();
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }

      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => {
          return `${partA} > ${partI}`;
        });
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
  prepareRoute(outlet: RouterOutlet) {
    return (outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']) || 'slideInAnimation';
  }

  onActivate(e) {
    return true;
    //@dherbe TODO:
    /*
    if (e.constructor.name === "login"){
      return;
    }
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
    */
  }
}
