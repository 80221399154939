<div class="page-wrap mat-bg-primary">
    <div class="session-form-hold">
        <!--  <mat-progress-bar mode="determinate" class="session-progress" style="width: 96.5%; padding-left: 15px;"></mat-progress-bar> -->
        <mat-card style="border-radius: 25px;">
            <mat-card-content>
                <div class="text-center pb-1" style="position:relative; margin-top: 10px">
                    <!--  <img src="assets/images/Frescura.png" alt="" class="mb-05" height="50px" *ngIf="!logo"> -->
                    <!-- <p style="font-size:2em; margin-bottom: 30px;">{{siteName}}</p> -->

                    <!-- Users -->
                    <div class="letters" style="display: flex; overflow-x: auto; width: 765px; margin-bottom: 15px;">
                        <button mat-raised-button color="secondary" *ngFor="let item of letters"
                            style="margin: 1px; border-radius: 0%; font-size: 0.8em;" (click)="seletedLetter(item)">
                            {{item | uppercase}}
                        </button>
                    </div>

                    <div class="Users"
                        style="display: flex; font-size: 1.1em; overflow-x: auto; width: 765px; padding-bottom: 10px;"
                        *ngIf="!HideItems">
                        <div class="userdetail" *ngFor="let item of users" (click)="setUser(item.username)"
                            [ngClass]="item.username == signinForm.controls['username'].value ? 'user-active' : ''">
                            <img src="assets/images/person.png" alt='' style="border-radius: 60px;" />
                            <div class="UserName">{{item.name}}</div>
                        </div>
                    </div>
                    <!-- Users end -->
                </div>

                <form [formGroup]="signinForm" (ngSubmit)="signin()">
                    <div style="display:none">
                        <mat-form-field class="full-width">
                            <input matInput name="username" [formControl]="signinForm.controls['username']"
                                placeholder="Username" value="" autocomplete="off">
                        </mat-form-field>
                        <small
                            *ngIf="signinForm.controls['username'].hasError('required') && signinForm.controls['username'].touched"
                            class="form-error-msg"> Username is required </small>
                    </div>

                    <div style="text-align: center;">
                        <mat-form-field class="full-width" style="position:relative">
                            <input type="password" name="password" matInput
                                [formControl]="signinForm.controls['password']" value="" maxlength="6"
                                style="text-align: center;font-size: 5em !important;letter-spacing: 0.3em;"
                                autocomplete="new-password">

                            <div style="position:absolute;top:25px;right:15px">
                                <button mat-raised-button style="height: 50px;"
                                    (click)="KeyboardNum('password');false;">
                                    <mat-icon *ngIf="KeyboardCount%2!=0 ;else ElseBlock">keyboard</mat-icon>
                                    <ng-template #ElseBlock>
                                        <mat-icon class="cancel">cancel</mat-icon>
                                    </ng-template>
                                </button>
                            </div>

                        </mat-form-field>
                        <small
                            *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched"
                            class="form-error-msg">Required</small>
                    </div>

                    <div style="text-align: center;">
                        <small
                            *ngIf="signinForm.controls['password'].hasError('required') && signinForm.controls['password'].touched"
                            class="form-error-msg">Required</small>
                    </div>

                    <div style="margin-top: 10px;">
                        <button mat-raised-button class="mat-primary full-width mb-1"
                            style="color:white; font-size: 1.5em; height: 50px;">Sign in</button>
                        <!-- <button mat-raised-button class="mat-primary full-width mb-1" style="color:white; font-size: 1.5em;">
                <a [routerLink]="'/sessions/forgot-password'" class="mat-primary text-center full-width">Forgot password</a>
              </button> -->
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>

<div>
    <switchUserNumberKeyboard *ngIf="OpenKeyboard" (dataupdateevent)="updatetitle($event)"
        (closeKeyboardop)="closekeyboard($event)"></switchUserNumberKeyboard>
</div>
<div>
    <app-numeric-key-pad-user-switcher *ngIf="OpenKeyboardNum" (dataupdateeventNum)="updatetitle($event)"
        (closeKeyboardopNum)="closekeyboard($event)"></app-numeric-key-pad-user-switcher>
</div>