
<div class="keyboard" >
      <div class="keyboard__key" *ngFor="let number of NumberButton" (click)="dataupdateevent.emit(number)" matRipple [matRippleColor]="secondary">
        <button type="submit" class="btn">{{number |translate}}</button>
      </div>
      <button type="button" class="keyboard__key keyboard__key--wide" (click)="dataupdateevent.emit('backspace')"><i class="material-icons">backspace</i></button>
    <br>
<!-- row1 -->
<div class="alp" >
    <button type="button" class="keyboard__key" *ngFor="let row1Alp of Row1Alphabets" (click)="dataupdateevent.emit(row1Alp)">{{row1Alp |translate}}</button>
    <button type="button" class="keyboard__key keyboard__key--wide keyboard__key--activatable" (click)="dataupdateevent.emit('_')" >_</button>
    <br>

<!-- row2 -->
    <button type="button" class="keyboard__key" *ngFor="let row21Alp of Row2Alphabets" (click)="dataupdateevent.emit(row21Alp)">{{row21Alp}}</button>
    <button type="button" class="keyboard__key keyboard__key--wide" (click)="dataupdateevent.emit(':')" >:</button>
    <br>

    <!-- Row3 -->
    <button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="closeKeyboardop.emit('close')">
      <i class="material-icons" >keyboard_arrow_down</i>
    </button>
    <button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="dataupdateevent.emit('/')">/</button>
    <button type="button" class="keyboard__key" *ngFor="let row31Alp of Row3Alphabets" (click)="dataupdateevent.emit(row31Alp)">{{row31Alp |translate}}</button>
    <button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="dataupdateevent.emit('clear')">clr</button>
    <br>
<!-- row4 -->
  <button type="button" class="keyboard__key keyboard__key--extra-wide" (click)="dataupdateevent.emit(' ')" ><i class="material-icons" >space_bar</i></button>
</div>





