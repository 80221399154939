import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { MatOptionModule, MatRippleModule } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatCardModule } from "@angular/material/card";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./components/header-side/header-side.component";
import { SidebarSideComponent } from "./components/sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./components/header-top/header-top.component";
import { SidebarTopComponent } from "./components/sidebar-top/sidebar-top.component";

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from "./components/customizer/customizer.component";

// ALL TIME REQUIRED
import { AdminLayoutComponent } from "./components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { SidenavComponent } from "./components/sidenav/sidenav.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { AppLoaderComponent } from "./services/app-loader/app-loader.component";

// DIRECTIVES
import { FontSizeDirective } from "./directives/font-size.directive";
import { ScrollToDirective } from "./directives/scroll-to.directive";
import { AppDropdownDirective } from "./directives/dropdown.directive";
import { DropdownAnchorDirective } from "./directives/dropdown-anchor.directive";
import { DropdownLinkDirective } from "./directives/dropdown-link.directive";
import { EgretSideNavToggleDirective } from "./directives/egret-side-nav-toggle.directive";

// PIPES
import { RelativeTimePipe } from "./pipes/relative-time.pipe";
import { ExcerptPipe } from "./pipes/excerpt.pipe";
import { GetValueByKeyPipe } from "./pipes/get-value-by-key.pipe";

// SERVICES
import { ThemeService } from "./services/theme.service";
import { LayoutService } from "./services/layout.service";
import { NavigationService } from "./services/navigation.service";
import { RoutePartsService } from "./services/route-parts.service";
import { AuthGuard } from "./services/auth/auth.guard";
import { AppLoaderService } from "./services/app-loader/app-loader.service";
import { HelpComponent } from "./components/help/help.component";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { AvatarModule } from "@progress/kendo-angular-layout";
import { CustomFilterComponent } from "./components/custom-filter/custom-filter.component";
import { MatInputModule } from "@angular/material/input";
/*
import { AttachmentListComponent } from './components/attachment-list/attachment-list.component';
import { AttachmentFormComponent } from './components/attachment-form/attachment-form.component';
*/

import { UserSwitcherComponent } from "./components/user-switcher/user-switcher.component";

import { Keyboard } from "./components/numberkeyboard/numberkeyboard.component";
import { NumericKeyPadComponent } from './components/numeric-key-pad/numeric-key-pad.component';
import { MulticheckFilterComponent } from "./components/multicheck-filter/multicheck-filter.component";
import { CustomSnackbarMessagesDialog } from "./components/custom-snackbar-messages-dialog/custom-snackbar-messages-dialog.component";

/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/

const classesToInclude = [
  HelpComponent,
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppLoaderComponent,
  CustomizerComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  CustomFilterComponent,
  UserSwitcherComponent,
  Keyboard,
  NumericKeyPadComponent,
  MulticheckFilterComponent,
  CustomSnackbarMessagesDialog
  //AttachmentListComponent,
  //AttachmentFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatDialogModule,
    PerfectScrollbarModule,
    WindowModule,
    AvatarModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  entryComponents: [AppLoaderComponent, HelpComponent],
  providers: [
    ThemeService,
    LayoutService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppLoaderService,
  ],
  declarations: classesToInclude,
  exports: classesToInclude,
})
export class SharedModule {}
