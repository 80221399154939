import {Injectable, Inject} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import { AppConfig } from 'app/app-config.module';
import { IRefdataModel } from './models/i-refdata.model';


@Injectable({
  providedIn: 'root'
})
export class RefdataService {
  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {
  }

  getIatfMapping<T>(categoryTypeId: number, categoryId: number, subCategoryId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/categoryMapping?categoryTypeId=' + categoryTypeId + '&categoryId=' + categoryId + '&subCategoryId=' + subCategoryId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSuppliers<T>(name:string  = null, code : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/suppliers';
    let params = {};
    if(name != null) params['name'] = name;
    if(code != null) params['code'] = code;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRequestors<T>(name:string  = null, cdsid : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/requestors';
    let params = {};
    if(name != null) params['name'] = name;
    if(cdsid != null) params['cdsid'] = cdsid;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  
  getSupplierCertifications<T>(supplierCode:string  = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/supplierCertifications';
    let params = {};
    if(supplierCode != null) params['supplierCode'] = supplierCode;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getByRefdataTypeId<T>(refdataTypeId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refdataTypeId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCountriesRefdata<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'activeCountries';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getExchangeRatesRefdata<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'activeExchangeRates';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getLocationInfo<T>(locationId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/location/${locationId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  create<T>(refdata: IRefdataModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/create';
    return this.http.post<T>(url, refdata).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  update<T>(refdata: IRefdataModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/update';
    return this.http.post<T>(url, refdata).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getRefdatasByType<T>(refdataTypeId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/data/${refdataTypeId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getRefdatasByParentId<T>(parentRefdataTypeId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/children/${parentRefdataTypeId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getCostCenters<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/costCenters`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getCostCentersByLocation<T>(locationId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/costCenters/${locationId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getLocationsByCostCenter<T>(costCenterId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/costCenterLocations/${costCenterId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
}
