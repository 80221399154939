
<div class="keyboard">
    <div class="keyboard__key" *ngFor="let number of NumberButton" (click)="dataupdateeventNum.emit(number)" matRipple [matRippleColor]="secondary">
      <button type="submit" class="btn">{{number |translate}}</button>
    </div>
    <button type="button" class="keyboard__key keyboard__key--wide" (click)="dataupdateeventNum.emit('backspace')"><i class="material-icons">backspace</i></button>
  <br>

<button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="dataupdateeventNum.emit('.')">.</button>
<button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="dataupdateeventNum.emit('clear')">clr</button>
<button type="button" class="keyboard__key keyboard__key--extra-wide" (click)="dataupdateeventNum.emit(' ')" ><i class="material-icons" >space_bar</i></button>
<button type="button" class="keyboard__key keyboard__key--wide keyboard__key--dark" (click)="closeKeyboardopNum.emit('close')">
    <i class="material-icons" >keyboard_arrow_down</i>
</button>
</div>





