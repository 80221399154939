<div class="sidebar-panel">
  <div
    id="scroll-area"
    [perfectScrollbar]
    class="navigation-hold"
    fxLayout="column"
  >
    <!-- App Logo -->
    <div
      class="branding default-bg no-bg"
      style="background: transparent !important"
    >
      <!-- Two different logos for dark and light themes -->
      <img
        src="assets/images/luis-silverio.jpg"
        alt=""
        class="app-logo-text"
        *ngIf="themeService.activatedTheme?.name?.indexOf('dark') !== -1"
        style="display: none"
      />
      <img
        src="assets/images/luis-silverio.jpg"
        alt=""
        class="app-logo-text"
        *ngIf="themeService.activatedTheme?.name?.indexOf('dark') === -1"
      />
    </div>

    <!-- Sidebar user -->
    <div class="app-user">
      <div
        *ngIf="this.layoutConf.sidebarStyle === 'full'"
        style="font-size: 0.9em; font-weight: normal; color: #ccc"
      >
        v{{ version }}
      </div>
      <!-- Small buttons -->
      <div class="app-user-controls">
        <button
          class="text-muted"
          mat-icon-button
          mat-xs-button
          matTooltip="Settings"
          [matMenuTriggerFor]="appUserMenu"
        >
          <mat-icon>settings</mat-icon>
        </button>
        <button
          class="text-muted"
          style="display: none"
          mat-icon-button
          mat-xs-button
          matTooltip="Inbox"
          routerLink="/inbox"
        >
          <mat-icon>email</mat-icon>
        </button>
        <button
          class="text-muted"
          mat-icon-button
          mat-xs-button
          matTooltip="Sign Out"
          (click)="signout()"
        >
          <mat-icon>exit_to_app</mat-icon>
        </button>

        <mat-menu #appUserMenu="matMenu">
          <button mat-menu-item (click)="navigateToProfile()">
            <mat-icon>account_box</mat-icon>
            <span>Profile</span>
          </button>
          <button mat-menu-item (click)="signout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <!-- Navigation -->
    <app-sidenav
      (helpClicked)="openHelp($event)"
      [items]="menuItems"
      [hasIconMenu]="hasIconTypeMenuItem"
    ></app-sidenav>
  </div>

  <span>
    <button
      class="custom-button"
      (click)="openMessagesDialog()"
      *ngIf="areMessagesAvailable()"
      style="
        position: absolute;
        bottom: 0px;
        align-items: center;
        justify-content: flex-start;
        padding: 8px 12px;
        border: none;
        background-color: transparent;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      "
    >
      <span *ngIf="successCount > 0" style="margin-right: 5px">
        <span
          style="
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(0, 173, 72);
            color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ successCount }}
        </span>
      </span>
      <span *ngIf="dangerCount > 0" style="margin-right: 5px">
        <span
          style="
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(173, 35, 0);
            color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ dangerCount }}
        </span>
      </span>
      <span *ngIf="warningCount > 0" style="margin-right: 5px">
        <span
          style="
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(249, 168, 37);
            color: black;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ warningCount }}
        </span>
      </span>
      <span *ngIf="infoCount > 0">
        <span
          style="
            min-width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: rgb(96, 118, 172);
            color: white;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          {{ infoCount }}
        </span>
      </span>
    </button>
  </span>
</div>

<app-help #sideHelp></app-help>
