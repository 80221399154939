import { Component, OnInit, Input, OnDestroy, Renderer2, ViewChild, Output, ElementRef } from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent implements OnInit {
  currentVideo : string = null;
  helpOpened : boolean = false;
  windowTitle : string = 'Help and How-To';
  windowWidth = 1124;
  windowHeight = 600;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  openWindow(){
    let help = this.router.url.replace(/[/]/g,'-').substring(1);
    this.currentVideo = 'assets/videos/' + help + ".mp4";
    switch(help){
      case 'sar-sar-dashboard':{
        this.windowTitle = 'Visteon SAR - How to work with SAR list';
      }break;
      case 'sar-create-sar':{
        this.windowTitle = 'Visteon SAR - Create and Submit';
      }break;
      case 'sar-sar-review':
      case 'sar-validate-sar':{
        this.currentVideo = 'assets/videos/sar-sar-review.mp4';
        this.windowTitle = 'Visteon SAR - Review and Approval';
      }break;
      case 'user-user-details':{
        this.windowTitle = 'Visteon SAR - Profile';
      }break;
      case 'dashboard':{
        this.windowTitle = 'Visteon SAR - My Activity';
      }break;
      case 'sar-sourcing-session-meeting':
      case 'sar-sourcing-session-agenda':
      case 'agenda-sourcing-session-meeting':
      case 'agenda-sourcing-session-agenda':{
        this.currentVideo = 'assets/videos/agenda-sourcing-session-agenda.mp4';
        this.windowTitle = 'Visteon SAR - Sourcing Session';
      }break;
      default:{
        this.windowTitle = 'Help and How-To';
        this.currentVideo = null;
        
      }break;
    }
    this.helpOpened = true;

  }
  closeWindow(){
    this.helpOpened = false;
  }
}
