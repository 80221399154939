import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../../user/state/user.reducer';
import { IUserModel } from '../models/i-user.model';

const getUserFeatureState = createFeatureSelector<UserState>('user');

export const getLoggedUserName = createSelector(
  getUserFeatureState,
  (state) => (state ? state.name : null)
);

export const getLoggedUserRoles = createSelector(
  getUserFeatureState,
  (state) => (state ? state.userRoles : null)
);

export const getLoggedUserIsAdmin = createSelector(
  getUserFeatureState,
  (state) => (state ? state.userRoles.includes('Admin') : null)
);

export const getSearchedUsers = createSelector(
  getUserFeatureState,
  (state) => (state ? state.searchedUsers : null)
);

export const getUsers = createSelector(
  getUserFeatureState,
  (state) => (state ? state.users : null)
);

export const getRoles = createSelector(
  getUserFeatureState,
  (state) => (state ? state.roles : null)
);

export const getRoleAttributes = createSelector(
  getUserFeatureState,
  (state) => (state ? state.roleAttributes : null)
);

export const getCurrentUserId = createSelector(
  getUserFeatureState,
  (state) => (state ? state.currentUserId : null)
);

export const getCurrentUser = createSelector(
  getUserFeatureState,
  (state) => (state ? state.currentUser : null)
);

export const getSelectedUser = createSelector(
  getCurrentUserId,
  getCurrentUser,
  (currentUserId, currentUser) => {
    if (currentUserId === '0') {
      const newObj: IUserModel = {
        id: '0',
        name: '',
        userName: '',
        cdsid: '',
        initials: '',
        email: '',
        phoneNumber: '',
        isActive: true,
        avatarUrl: '',
        superUser: null,
        notificationsFrequency: null,
        channels: null,
        userRoles: [],
        orgs: null,
        plants: null,
        storageLocations: null,
        departments: null,
      };
      return newObj;
    } else {
      return currentUser && currentUser.id === currentUserId ? currentUser : null;
    }
  }
);
