import {createFeatureSelector, createSelector} from '@ngrx/store';
import {CoreState} from './core.reducer';

const getCoreFeatureState = createFeatureSelector<CoreState>('core');

export const getFeedbacks = createSelector(
  getCoreFeatureState,
  state => {
    return state ? state.feedbacks : null;
  }
);
