import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
} from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Observable, Subscription } from "rxjs";
import { select, Store } from "@ngrx/store";
import * as fromUser from "../../../views/user/state";
import { AuthGuard } from "app/shared/services/auth/auth.guard";
import { Router } from "@angular/router";
import * as userActions from "../../../views/user/state/user.actions";
import { HelpComponent } from "../help/help.component";
import { AuthService } from "app/views/user/auth.service";
import packageJson from "../../../../../package.json";
import { LayoutService } from "app/shared/services/layout.service";
import { CustomSnackbarService } from "app/shared/services/custom-snackbar.service";
import { MatDialog } from "@angular/material/dialog";
import { CustomSnackbarMessagesDialog } from "../custom-snackbar-messages-dialog/custom-snackbar-messages-dialog.component";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("sideHelp") help: HelpComponent;

  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public loggedUserName$: Observable<string>;
  version: string = packageJson.version;
  public layoutConf: any;
  messages: { message: string; type?: string }[] = [];
  successCount = 0;
  dangerCount = 0;
  warningCount = 0;
  infoCount = 0;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private storeUser: Store<fromUser.State>,
    private authGuard: AuthGuard,
    private store: Store<fromUser.State>,
    private router: Router,
    private authService: AuthService,
    private layout: LayoutService,
    private snackbarService: CustomSnackbarService,
    private dialog: MatDialog
  ) {

    this.snackbarService.snackbarSubject.subscribe(() => {
      this.fetchMessages();
    });

  }

  ngOnInit() {
    this.fetchMessages();
    this.loggedUserName$ = this.storeUser.pipe(
      select(fromUser.selectors.getLoggedUserName)
    );
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.layoutConf = this.layout.layoutConf;
    this.menuItemsSub = this.navService.menuItems$.subscribe((items) => {
      //@dherbe added ability to filter menuitem based on the user roles
      var menu = [];
      for (let i = 0; i < items.length; i++) {
        let canAdd = false;
        if (items[i].roles != null && items[i].roles.length > 0) {
          for (let r = 0; r < items[i].roles.length; r++) {
            if (this.authGuard.isInRole(items[i].roles[r])) {
              canAdd = true;
              break;
            }
          }
        } else {
          canAdd = true;
        }

        if (canAdd) menu.push(items[i]);
      }
      this.menuItems = menu;
      // Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        (item) => item.type === "icon"
      ).length;
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  fetchMessages() {
    this.messages = this.snackbarService.getMessages();

    this.successCount = this.messages.filter(
      (msg) => msg.type === "success"
    ).length;

    this.dangerCount = this.messages.filter(
      (msg) => msg.type === "danger"
    ).length;

    this.warningCount = this.messages.filter(
      (msg) => msg.type === "warning"
    ).length;

    this.infoCount = this.messages.filter((msg) => msg.type === "info").length;
  }

  areMessagesAvailable(): boolean {
    return (
      this.successCount > 0 ||
      this.dangerCount > 0 ||
      this.warningCount > 0 ||
      this.infoCount > 0
    );
  }

  navigateToProfile() {
    let userId = this.authGuard.getProperty("id");
    this.store.dispatch(new userActions.SelectedUserId(userId));
    this.store.dispatch(new userActions.GetUser(userId));
    this.router.navigate(["/user/user-details"]);
  }

  signout() {
    localStorage.clear();
    this.authService.logout().subscribe((x) => {});
    this.router.navigate(["/sessions/signin"]);
  }

  openHelp(evt: any) {
    this.help.openWindow();
  }

  openMessagesDialog() {
    this.dialog.open(CustomSnackbarMessagesDialog, {
      width: "65%",
      height: "80%"
    });
  }
}
