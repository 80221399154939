import { ILoggedUserModel } from "../models/i-logged-user.model";
import { IFeedbackModel } from "../../core/models/i-feedback.model";
import { UserActions, UserActionTypes } from "./user.actions";
import { IUserDashboardModel } from "../models/user-list/i-user-dashboard.model";
import { IRoleModel } from "../models/create-user/i-role.model";
import { IRoleAttributeModel } from "../models/create-user/i-role-attribute.model";
import { IUserModel } from "../models/i-user.model";

export interface UserState {
  feedbacks: IFeedbackModel[];
  token: string;
  name: string;
  userRoles: string[];
  searchedUsers: any[];
  users: IUserModel[];
  dashboardUsers: IUserDashboardModel[];
  roles: IRoleModel[];
  roleAttributes: IRoleAttributeModel[];
  currentUserId: string;
  currentUser: IUserModel;
  isDelegate: boolean;
}

const initialState: UserState = {
  feedbacks: [],
  token: null,
  name: "",
  userRoles: [],
  searchedUsers: [],
  users: [],
  dashboardUsers: [],
  roles: [],
  roleAttributes: [],
  currentUserId: null,
  currentUser: null,
  isDelegate: false,
};

export function userReducer(
  state = initialState,
  action: UserActions
): UserState {
  switch (action.type) {
    case UserActionTypes.InitializeUser:
      return {
        ...state,
        currentUserId: "0",
      };
    case UserActionTypes.CreateSuccess:
      return {
        ...state,
      };
    case UserActionTypes.CreateFail:
      return {
        ...state,
        feedbacks: action.payload,
      };

    case UserActionTypes.Update:
      const updatedUser = action.payload;
      const updatedUsers = state.users.map((user) =>
        user.id === updatedUser.id ? { ...user, ...updatedUser } : user
      );
      const updatedModel = {
        ...state,
        users: updatedUsers,
        dashboardUsers: updatedUsers,
        currentUser: action.payload,
      };
      localStorage.setItem('updatedUserModelValues', JSON.stringify(updatedModel));
      return updatedModel;

    case UserActionTypes.UpdateSuccess:
      return {
        ...state,
      };
    case UserActionTypes.UpdateFail:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.LoginSuccess:
      return {
        ...state,
        token: action.payload.token,
        name: action.payload.name,
        userRoles: action.payload.roles,
        isDelegate: action.payload.isDelegate,
      };
    case UserActionTypes.LoginFail:
      return {
        ...state,
        token: null,
        feedbacks: action.payload,
      };
    case UserActionTypes.SearchSuccess:
      return {
        ...state,
        searchedUsers: action.payload,
      };
    case UserActionTypes.SearchFail:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.GetUsersSuccess:
      return {
        ...state,
        users: action.payload,
      };
    case UserActionTypes.GetUsersError:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.GetDashboardUsersSuccess:
      return {
        ...state,
        dashboardUsers: action.payload,
      };
    case UserActionTypes.GetDashboardUsersError:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.GetRolesSuccess:
      return {
        ...state,
        roles: action.payload,
      };
    case UserActionTypes.GetRolesError:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.GetRoleAttributesSuccess:
      return {
        ...state,
        roleAttributes: action.payload,
      };
    case UserActionTypes.GetRoleAttributesError:
      return {
        ...state,
        feedbacks: action.payload,
      };
    case UserActionTypes.SelectedUserId:
      return {
        ...state,
        currentUserId: action.payload,
      };
    case UserActionTypes.GetUserSuccess:
      return {
        ...state,
        currentUser: action.payload,
      };
    case UserActionTypes.GetUserError:
      return {
        ...state,
        feedbacks: action.payload,
      };
    default:
      return state;
  }
}
