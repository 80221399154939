import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import { AppConfig } from 'app/app-config.module';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private router: Router
  ) {
  }
 
  getApp<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'App';
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getUsers(str){
    let data='?keyword='+str;
    const url = this.config.getConfig('apiEndpoint') + 'users/dashboard'+data;
    return this.http.get(url);
  }

 
}
