import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomSnackbarService } from "app/shared/services/custom-snackbar.service";

@Component({
  selector: "app-custom-snackbar-messages-dialog",
  templateUrl: "./custom-snackbar-messages-dialog.component.html",
  styleUrls: ["./custom-snackbar-messages-dialog.component.scss"],
})
export class CustomSnackbarMessagesDialog implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomSnackbarMessagesDialog>,
    private snackbarService: CustomSnackbarService,
  ) {}

  dataSource: any[];

  ngOnInit() {
    this.dataSource = this.snackbarService.getMessages();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
