import { Component, OnInit, OnDestroy } from "@angular/core";
import { CustomSnackbarService } from "../../services/custom-snackbar.service";
import { Subscription } from "rxjs";

import { trigger, transition, animate, style } from "@angular/animations";

@Component({
  selector: "app-custom-snackbar",
  templateUrl: "./custom-snackbar.component.html",
  styleUrls: ["./custom-snackbar.component.scss"],
  animations: [
    trigger("state", [
      transition(":enter", [
        style({
          bottom: "-100px",
          transform: "translate(-50%, 0%) scale(0.3)",
        }),
        animate(
          "150ms cubic-bezier(0, 0, 0.2, 1)",
          style({
            transform: "translate(-50%, 0%) scale(1)",
            opacity: 1,
            bottom: "20px",
          })
        ),
      ]),
      transition(":leave", [
        animate(
          "150ms cubic-bezier(0.4, 0.0, 1, 1)",
          style({
            transform: "translate(-50%, 0%) scale(0.3)",
            opacity: 0,
            bottom: "-100px",
          })
        ),
      ]),
    ]),
  ],
})
export class CustomSnackbarComponent implements OnInit, OnDestroy {
  show = false;
  message: string = "";
  type: string = "success";
  snackbarSubscription: Subscription;

  constructor(private snackbarService: CustomSnackbarService) {}

  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbarState.subscribe(
      (state) => {

        var duration = state.duration || 4000;
        if (state.type) {
          this.type = state.type;
        } else {
          this.type = "info";
        }
        this.message = state.message;
        this.show = state.show;

        setTimeout(() => {
          this.show = false;
        }, duration);

      }

    );
  }

  hide(){
    this.show = false;
  }
  ngOnDestroy() {
    this.snackbarSubscription.unsubscribe();
  }
}
