<h1
  mat-dialog-title
  style="
    display: flex;
    justify-content: space-between;
    margin: 10px;
    font-weight: 300;
  "
>
  <mat-icon
    style="font-size: 2rem; color: var(--secondary-color); margin-right: 20px"
    (click)="closeDialog()"
    >cancel</mat-icon
  >
</h1>

<div class="message-container">
  <mat-card
    *ngFor="let msg of dataSource"
    class="message-card"
    [ngClass]="msg.type"
  >
    <mat-card-content>
      <h5>{{ msg.message }}</h5>
      <p *ngIf="msg.type">
        {{ msg.type | translate }}
      </p>
      <div style="position: absolute;right:15px;bottom:15px;">{{ msg.datetime }}</div>
    </mat-card-content>
  </mat-card>
</div>
