import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IAppModuleModel, IAppSettingsModel } from '../models/i-app-setting.model';
import { ColorThemeService } from 'app/views/others/induster-pages/IndusterServices/color-theme.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly appSettingsKey: string = 'App-Settings';

  constructor(private translate: TranslateService, private colorThemeService: ColorThemeService) {
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public setAppSettings(appSettings: IAppSettingsModel){
    localStorage.setItem(this.appSettingsKey, JSON.stringify(appSettings));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public getAppSettings(): IAppSettingsModel{
    const appSettingsString = JSON.parse(localStorage.getItem(this.appSettingsKey));

    const colorTheme = this.colorThemeService.getColorTheme(appSettingsString.name);
    this.applyColorTheme(colorTheme);

    return appSettingsString as IAppSettingsModel;
  }

  applyColorTheme(colorTheme: any) {
    document.documentElement.style.setProperty('--primary-color', colorTheme?.primaryColor);
    document.documentElement.style.setProperty('--secondary-color', colorTheme?.secondaryColor);
    document.documentElement.style.setProperty('--tertiary-color', colorTheme?.tertiaryColor);
    document.documentElement.style.setProperty('--quartiary-color', colorTheme?.quartiaryColor);
    document.documentElement.style.setProperty('--quintiary-color', colorTheme?.quintiaryColor);
    document.documentElement.style.setProperty('--hexatiary-color', colorTheme?.hexatiaryColor);
  }

  public getAppCurrencyCode(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.currencyCode;
  }

  public getAppLocale(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.locale;
  }

  public getAvailableModules(): IAppModuleModel[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules;
  }

  public getPlantsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.plantsSingleMode;
  }

  public getStorageLocationsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.storageLocationsSingleMode;
  }

  public getFormConfigs(): any[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules.map(x => x.settings?.formConfigs);
    // return [
    //   {
    //     optionalGroup: {
    //       costCentre: false,
    //       eProj: false,
    //       par: false,
    //       parLineItem: false,
    //       itemDescriptionPartNumber: false
    //     }
    //   },
    // ]
  }

}
