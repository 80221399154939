import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AuthGuard } from "app/shared/services/auth/auth.guard";
import { CoreService } from "app/views/core/core.service";
import { AuthService } from "app/views/user/auth.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import * as fromUser from "../../../views/user/state";
import * as userActions from "../../../views/user/state/user.actions";
import { MatDialog } from "@angular/material/dialog";
import { CustomSnackbarService } from "app/shared/services/custom-snackbar.service";

@Component({
  selector: "app-user-switcher",
  templateUrl: "./user-switcher.component.html",
  styleUrls: ["./user-switcher.component.scss"],
})
export class UserSwitcherComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  users: any;
  selectedUser: string;
  letters = [];
  allUsers: any;
  HideItems: boolean = true;
  signinForm: FormGroup;
  OpenKeyboard: boolean = false;
  KeyboardCount: number = 1;
  textBoxToFill: string = "";
  DataFromchild: any = "";
  Flag: boolean = false;
  OpenKeyboardNum: boolean = false;

  constructor(
    private authService: AuthService,
    private store: Store<fromUser.State>,
    private authGuard: AuthGuard,
    private coreService: CoreService,
    private translate: TranslateService,
    private _customSnackbar: CustomSnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getUser();

    this.signinForm = new FormGroup({
      username: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      rememberMe: new FormControl(false),
    });
  }

  getUser() {
    setTimeout(() => {
      this.HideItems = false;
      this.coreService.getUsers("").subscribe((res) => {
        this.users = res;
        this.allUsers = res;

        this.users.forEach((ele) => {
          let firstLetter = ele.name.substring(0, 1);
          this.letters.push(firstLetter);
        });
        this.letters = [...new Set(this.letters)];
      });
    }, 1000);
  }

  signin() {
    this.blockUI.start();
    this.authGuard.logout();
    const signinData = this.signinForm.value;

    if (!signinData.username) {
      this.translate.get("Select a user to continue").subscribe((msg) => {
        this._customSnackbar.show(msg, 'warning');
      });
      return;
    }

    const login = {
      userName: signinData.username,
      password: signinData.password,
    };
    this.store.dispatch(new userActions.InitializeUser());
    this.store.dispatch(new userActions.StartUser(signinData.username));
    this.store.dispatch(new userActions.Login(login));
    sessionStorage.setItem("loggedUserName", signinData.username);
    this.dialog.closeAll();
  }

  seletedLetter(item) {
    this.users = this.allUsers;
    if (item?.length > 0) {
      this.users = this.allUsers.filter((val) =>
        val.name.toLowerCase().startsWith(item.toLowerCase())
      );
    }
  }

  setUser(selectedvalue: string) {
    let value = selectedvalue;
    this.signinForm.get("username").setValue(value);
  }

  Keyboard(data) {
    this.KeyboardCount++;
    this.OpenKeyboard = this.KeyboardCount % 2 == 0 ? true : false;
    // this.Flag=this.KeyboardCount%2==0 && data=='username'?true:false;
    this.DataFromchild = "";
    this.textBoxToFill = data;
  }

  KeyboardNum(data) {
    this.KeyboardCount++;
    this.OpenKeyboardNum = this.KeyboardCount % 2 == 0 ? true : false;
    this.DataFromchild = "";
    this.textBoxToFill = data;
  }

  closekeyboard(val: any) {
    this.OpenKeyboard = false;
    this.OpenKeyboardNum = false;
  }

  updatetitle(title: any) {
    if (title === "backspace") {
      this.DataFromchild = this.DataFromchild.substring(
        0,
        this.DataFromchild.length - 1
      );
      this.signinForm.get(this.textBoxToFill).setValue(this.DataFromchild);
    } else if (title === "clear") {
      this.DataFromchild = "";
      this.signinForm.get(this.textBoxToFill).setValue(this.DataFromchild);
    } else {
      this.DataFromchild += title;
      // this.fliterString=this.DataFromchild;
      this.signinForm.get(this.textBoxToFill).setValue(this.DataFromchild);
    }
  }
}
