import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TitleComponent } from '@progress/kendo-angular-charts';
import { BottomNavigationSelectEvent } from '@progress/kendo-angular-navigation';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CommonServiceService } from '../../../views/others/induster-pages/IndusterServices/common-service.service';


@Component({
  selector: 'switchUserNumberKeyboard',
  templateUrl: 'numberkeyboard.component.html',
  styleUrls: ['numberkeyboard.component.css']
})


export class Keyboard implements OnInit {

  @Output() dataupdateevent= new EventEmitter();
  @Output() closeKeyboardop= new EventEmitter();

  constructor(public translate:TranslateService,private _commonServ:CommonServiceService) {
  }
  NumberButton=[1,2,3,4,5,6,7,8,9,0];
  Row1Alphabets=['q','w','e','r','t','y','u','i','o','p'];
  Row2Alphabets=['a','s','d','f','g','h','j','k','l'];
  Row3Alphabets=['z','x','c','v','b','n','m',',','.','?'];
  Search:string='';
  shownumbersOnly:boolean=true;




  result: string = '';


  ngOnInit(): void {

   
    
  }
  
  clear(){
    this.result = ''
  }
  backspace(){
    this.result = this.result.slice(0, this.result.length-1)
  }
  add(number){
    this.result = this.result + number 
  }

 

  // KeyPressValue(Keyvalue:any)
  // {
  
  //   if(Keyvalue==='backspace'){
  //     this.Search=this.Search.substring(0,this.Search.length-1);
  //   }
  //   else if(Keyvalue==='collen'){
  //     this.Search+=':';
  //   }
  //   else if(Keyvalue==='forwardSlach'){
  //     this.Search+="/";
  //   }
  //   else if(Keyvalue==='space'){
  //     this.Search+=" ";
  //   }
  //   else if(Keyvalue==='underscore'){
  //     this.Search+='_';
  //   }
  //   else{
  //     this.Search+=Keyvalue;
  //   }
    
  //}



}


