import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ColorThemeService {
  private colorThemes: any;

  fallbackTheme = {
    default: {
      primaryColor: "#007bff",
      secondaryColor: "#6c757d",
      tertiaryColor: "#28a745",
      quartiaryColor: "#dc3545",
      quintiaryColor: "#ffc107",
      hexatiaryColor: "#17a2b8",
    },
  };

  constructor(private http: HttpClient) {
    this.loadColorThemes();
  }

  private loadColorThemes() {
    this.http.get("assets/color-config.json").subscribe(
      (data) => (this.colorThemes = data),
      () => (this.colorThemes = this.fallbackTheme)
    );
  }

  getColorTheme(appName: string): any {
    if(!this.colorThemes){
      return this.fallbackTheme;
    }

    return this.colorThemes[appName] || this.fallbackTheme;
  }


}
