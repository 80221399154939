import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonServiceService } from '../../../views/others/induster-pages/IndusterServices/common-service.service';

@Component({
  selector: 'app-numeric-key-pad-user-switcher',
  templateUrl: './numeric-key-pad.component.html',
  styleUrls: ['./numeric-key-pad.component.scss']
})
export class NumericKeyPadComponent implements OnInit {

  @Output() dataupdateeventNum= new EventEmitter();
  @Output() closeKeyboardopNum= new EventEmitter();

  constructor(public translate:TranslateService,private _commonServ:CommonServiceService) {
  }
  NumberButton=[1,2,3,4,5,6,7,8,9,0];

  Search:string='';
  shownumbersOnly:boolean=true;




  result: string = '';


  ngOnInit(): void {

   
    
  }
  
  clear(){
    this.result = ''
  }
  backspace(){
    this.result = this.result.slice(0, this.result.length-1)
  }
  add(number){
    this.result = this.result + number 
  }

 
}


