<app-error></app-error>
<!-- If large screen -->
<div (window:resize)="onResize()" [@routeAnimations]="prepareRoute(outlet)" *ngIf="!isMobile">
  <ng-container *ngTemplateOutlet="routeroutlet">
  </ng-container>
</div>

<!-- If small screen -->
<div (window:resize)="onResize()" [@routeAnimations]="prepareRoute(outlet)" *ngIf="isMobile">
  <ng-container *ngTemplateOutlet="routeroutlet">
  </ng-container>
</div>

<ng-template #routeroutlet>
  <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
</ng-template>

<!-- Our custom made snack bar -->
<!-- call it inside components to display messages -->
<app-custom-snackbar></app-custom-snackbar>
