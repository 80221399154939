import {RefdataActions, RefdataActionTypes, RefdataType} from './refdata.actions';
import {IFeedbackModel} from '../../core/models/i-feedback.model';
import {IRefdataModel} from '../models/i-refdata.model';
import {ICountryRefdataModel} from '../models/i-country-refdata.model';
import {IExchangeRateRefdataModel} from '../models/i-exchange-rate-refdata.model';

export interface RefdataState {
  feedbacks: IFeedbackModel[];
  selectedId: number;
  regions: IRefdataModel[];
  locations: IRefdataModel[];
  usedEquipments: IRefdataModel[];
  indirectPurchasingCategories: IRefdataModel[];
  // categoryCodeDescriptions: IRefdataModel[];
  // countries: IRefdataModel[];
  fhaRates: IRefdataModel[];
  currencies: IRefdataModel[];
  paymentTerms: IRefdataModel[];
  certifications: IRefdataModel[];

  categoryType: IRefdataModel[];
  category: IRefdataModel[];
  subCategory: IRefdataModel[];
  countries: ICountryRefdataModel[];
  exchangeRates: IExchangeRateRefdataModel[];

  certTypes:IRefdataModel[]; 
  businessGroups: IRefdataModel[];
  costCenters: IRefdataModel[];
  sources: IRefdataModel[];
}

const initialState: RefdataState = {
  feedbacks: [],
  selectedId: null,
  // categoryCodeDescriptions: [],
  // countries: [],
  currencies: [],
  fhaRates: [],
  indirectPurchasingCategories: [],
  locations: [],
  paymentTerms: [],
  regions: [],
  usedEquipments: [],
  certifications: [],

  categoryType: [],
  category: [],
  subCategory: [],
  countries: [],
  exchangeRates: [],

  sources: [],
  businessGroups: [],
  costCenters: [],
  certTypes: []
};

export function refdataReducer(state = initialState, action: RefdataActions): RefdataState {

  switch (action.type) {
    case RefdataActionTypes.StartRefdata:
      return {
        ...state
      };
    case RefdataActionTypes.GetRefdataSuccess:
      switch (action.payload.refdataTypeId) {
        // case RefdataType.categoryCodeDescription:
        //   return {
        //     ...state,
        //     categoryCodeDescriptions: [...state.categoryCodeDescriptions, ...action.payload.refdatas]
        //   };
        // case RefdataType.country:
        //   return {
        //     ...state,
        //     countries: [...state.countries, ...action.payload.refdatas]
        //   };
        // case RefdataType.currency:
        //   return {
        //     ...state,
        //     currencies: [...state.currencies, ...action.payload.refdatas]
        //   };
        case RefdataType.fhaRate:
          return {
            ...state,
            fhaRates: [...action.payload.refdatas]
          };
        case RefdataType.indirectPurchasingCategory:
          return {
            ...state,
            indirectPurchasingCategories: [...action.payload.refdatas]
          };
        case RefdataType.location:
          return {
            ...state,
            locations: [...action.payload.refdatas]
          };
        case RefdataType.paymentTerms:
          return {
            ...state,
            paymentTerms: [...action.payload.refdatas]
          };
        case RefdataType.region:
          return {
            ...state,
            regions: [...action.payload.refdatas]
          };
        case RefdataType.usedEquipment:
          return {
            ...state,
            usedEquipments: [...action.payload.refdatas]
          };
        case RefdataType.certification:
          return {
            ...state,
            certifications: [...action.payload.refdatas]
          };
        case RefdataType.categoryType:
          return {
            ...state,
            categoryType: [...action.payload.refdatas]
          };
        case RefdataType.category:
          return {
            ...state,
            category: [...action.payload.refdatas]
          };
        case RefdataType.subCategory:
          return {
            ...state,
            subCategory: [...action.payload.refdatas]
          };
        case RefdataType.sources:
          return {
            ...state,
            sources: [...action.payload.refdatas]
          };
        case RefdataType.certType:
          return {
            ...state,
            certTypes: [...action.payload.refdatas]
          };
        case RefdataType.businessGroup:
          return {
            ...state,
            businessGroups: [...action.payload.refdatas]
          };
        case RefdataType.costCenter:
          return {
            ...state,
            costCenters: [...action.payload.refdatas]
          };

      }
      return {
        ...state
      };
    case RefdataActionTypes.GetRefdataError:
      return {
        ...state
      };
    case RefdataActionTypes.GetCountriesSuccess:
      return {
        ...state,
        countries: action.payload
      };
    case RefdataActionTypes.GetCountriesError:
      return {
        ...state,
        feedbacks: action.payload
      };
    case RefdataActionTypes.GetExchangeRatesSuccess:
      return {
        ...state,
        exchangeRates: action.payload
      };
    case RefdataActionTypes.GetExchangeRatesError:
      return {
        ...state,
        feedbacks: action.payload
      };
    default:
      return state;
  }
}
