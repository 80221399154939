import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "app/app-config.module";

@Injectable({
  providedIn: "root",
})
export class ShipmentService {
  constructor(private _http: HttpClient, private config: AppConfig) {}
  private url: string = this.config.getConfig("apiEndpoint");

  getShipments(plantId: string, weightType: number = 2, customerId?: string) {
    return this._http.get(
      customerId
        ? this.url +
            "shipment?plantId=" +
            plantId +
            "&weightType=" +
            weightType +
            "&customerId=" +
            customerId
        : this.url +
            "shipment?plantId=" +
            plantId +
            "&weightType=" +
            weightType +
            "&customerId="
    );
  }

  /*
    Retrives the list of shipment documents for the provided weightType (if applicable)
  */
  getAllShipments(plantId: string, weightType: number) {
    let baseUrl = this.url + "shipment?plantId=" + plantId;
    if (weightType) {
      baseUrl += "&weightType=" + weightType;
    }
    return this._http.get(baseUrl);
  }

  getShipmentItems<T>(docNumber: string, weightType : number = null) {
    let url = this.url + `shipment/items?docNumber=` + docNumber;
    if(weightType != null){
      url += `&weightType=` + weightType;
    }
    return this._http.get<T>(url);
  }

  saveShipments<T>(shipments: any) {
    return this._http.put(this.url + "Shipment", shipments);
  }

  savePOProducts(list) {
    return this._http.put(this.url + "PurchaseOrder", list);
  }
}
