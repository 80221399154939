import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app-config.module';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationMenuServiceService {

  constructor(private config:AppConfig,private _http:HttpClient) { }
  private url:string=this.config.getConfig('apiEndpoint')


  setModules(modules){
    localStorage.setItem("ams:modules", JSON.stringify(modules))
  }

  getModules(){
    const modules = localStorage.getItem("ams:modules");
    if(modules){
      return JSON.parse(modules);
    }
    return [];
  }

  getSettings(moduleName : string){
    const modules = this.getModules();
    if(modules && modules.find(x => x.module == moduleName)){
      const settings = modules.find(x => x.module == moduleName).settings;
      if(settings && settings != ''){
        return JSON.parse(settings);
      }
    }
    return {};
  }

  getAppModules(){
    return this._http.get(this.url + 'app/modules');
  }
}
