import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

interface IMenuItem {
  type: string;       // Possible values: link/dropDown/icon/separator/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles : string[];   // @dherbe added to be able to filter item based on user roles
  moduleName? : string;
}

interface IChildItem {
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  icon?: string;
  sub?: IChildItem[];
  roles? : string[];
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {

  defaultMenu: IMenuItem[] = [
    {
      name: 'Facilities',
      type: 'link',
      icon: 'factory',
      state: 'induster/overview',
      roles: []
    },
    {
      name: 'My Work',
      type: 'link',
      icon: 'precision_manufacturing',
      state: 'induster/mywork',
      roles: ['Admin', 'Operator', 'Logistics', 'Supervisor']
    },
    {
      name: 'Monitoring',
      type: 'link',
      icon: 'bar_chart',
      state: 'induster/production/monitoring',
      roles: ['Supervisor','Admin'],
      moduleName: 'Operator'
    },
    {
      name: 'Users',
      type: 'link',
      icon: 'group',
      state: 'user/user-dashboard',
      roles: ['Supervisor', 'Admin']
    },
    {
      name: 'Preferences',
      type: 'link',
      icon: 'settings',
      state: 'induster/preferences',
      roles: ['SuperUser']
    }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = '';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  constructor() {
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.defaultMenu);
  }

}
