import { Injectable, Inject } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICreateUserModel} from './models/create-user/i-create-user.model';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import {ICoreSearchModel} from '../core/models/i-core-search.model';
import { AppConfig } from 'app/app-config.module';
import { IUserDelegationModel } from './models/i-user-delegation.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private config: AppConfig
    ) {
  }

  getById<T>(req: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `users/${req}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  search<T>(req: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/search/${req}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUsers<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'Users/users';
    return this.http.get<T>(url)
    .pipe(catchError(HttpUtils.handleHttpClientError));
  }

  getDashboard<T>(req: ICoreSearchModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/dashboard`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRoles<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/roles`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRoleAttributes<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/roleAttributes`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDelegations<T>(userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/delegations?userId=${userId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  addDelegation<T>(delegation: IUserDelegationModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/addDelegation`;
    return this.http.post<T>(url, delegation).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  stopDelegation<T>(delegationId: number, userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/stopDelegation';

    return this.http.post<T>(url, {id: delegationId, userId: userId}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  deleteDelegation<T>(delegationId: number, userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/deleteDelegation';

    return this.http.post<T>(url, {id: delegationId, userId: userId}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getUsersToDelegate<T>(userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/delegatedTo?userId=${userId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getUsersByRole<T>(roleId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/byRole/${roleId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
}
