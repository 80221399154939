import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class FacilitesServiceService {


  constructor(private config:AppConfig,private _http:HttpClient) { }
  private url:string=this.config.getConfig('apiEndpoint');


// Fetching Facility For OverViewScreen
  getAllFacilities(){
    return this._http.get(this.url + 'Facility');
  }

  getConfig(){
    return this._http.get(this.url + 'app/modules');
    
  }

}
