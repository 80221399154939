import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonServiceService {
  constructor() {}
  // creating subject to store value of LocName & LocId &selectedModule start
  selectedLocationName = new BehaviorSubject("");
  selectedLocationId = new BehaviorSubject("");
  selectedModuleId = new BehaviorSubject("");
  showNumberKeyboard = new BehaviorSubject(false);

  // creating subject to store value of SupplierId from Product screen
  selectedSpplierFrom_Prod = new BehaviorSubject("");

  // NewProduct object
  NewProduct = new Subject();

  setPlant(plant : any){
    if(!plant){
      localStorage.removeItem('amf-plant');
      return;
    }
    localStorage.setItem('amf-plant', JSON.stringify(plant));
    this.selectedLocationName.next(plant.plantName);
    this.selectedLocationId.next(plant.plantId);
  }
  /*
    Gets whether there is a context plant set
  */
  hasPlant() : boolean {
    return this.getPlant() != null ? true : false;
  }

  public get PlantId() : string {
    let plant = this.getPlant();
    if(plant == null){
      return null;
    }
    return plant.plantId
  }

  public get PlantName() : string {
    let plant = this.getPlant();
    if(plant == null){
      return null;
    }
    return plant.plantName
  }

  getPlant(){
    let json = localStorage.getItem('amf-plant');
    if(!json) return null;
    return JSON.parse(json);
  }
  /*
    Removes an item from the array by id
  */
  removeNewProduct(id) {
    let newProdlist = [];
    if (localStorage.getItem("newPd")) {
      newProdlist = JSON.parse(localStorage.getItem("newPd"));
    }
    newProdlist = newProdlist.filter(x => x.id != id);
    localStorage.setItem("newPd", JSON.stringify(newProdlist));
  }
    /*
    Creates a new Weight record to the persist to local storage
  */
  saveNewProduct(newItem) {
    let newProdlist = [];
    if (localStorage.getItem("newPd")) {
      newProdlist = JSON.parse(localStorage.getItem("newPd"));
      newProdlist = [newItem, ...newProdlist];
    } else {
      newProdlist = [newItem];
    }
    localStorage.setItem("newPd", JSON.stringify(newProdlist));
    return "Success";
  }

  /*
    Creates a new Weight record to the persist to local storage
  */
  saveShipmentItem(newItem) {
    let newProdlist = [];
    if (localStorage.getItem("newPd")) {
      newProdlist = JSON.parse(localStorage.getItem("newPd"));
    }
    for(var j = 0; j < newProdlist.length; j++){
      let parent = newProdlist[j];

      if(parent.id == newItem.parentId) {
        let found = false;
        for(var i = 0; i < parent.items.length; i++){
          if(parent.items[i].id == newItem.id){
            newProdlist[j].items[i] = newItem;
            found = true;
            break;
          }
        }
        if(!found){
          parent.items.push(newItem);
        }
      }
    }
    localStorage.setItem("newPd", JSON.stringify(newProdlist));
  }

  deleteShipmentItem(deleteItem){
    let newProdlist = [];
    if (localStorage.getItem("newPd")) {
      newProdlist = JSON.parse(localStorage.getItem("newPd"));
    }
    for(var j = 0; j < newProdlist.length; j++){
      let parent = newProdlist[j];

      if(parent.id == deleteItem.parentId) {
        newProdlist[j].items = parent.items.filter(x=> x.id != deleteItem.id);
      }
    }
    localStorage.setItem("newPd", JSON.stringify(newProdlist));
  }

  saveReceptions(data) {
    localStorage.setItem("newPd", JSON.stringify(data));
  }

  // getting items
  fetchNewProducts() {
    let allNewproducts = [];
    if (localStorage.getItem("newPd")) {
      allNewproducts = JSON.parse(localStorage.getItem("newPd"));
    }
    return allNewproducts;
  }

  fetchNewProductsbyId(id) {
    if (localStorage.getItem("newPd")) {
      let product = JSON.parse(localStorage.getItem("newPd"));
      return product?.find((val: any) => val.id == id);
    }
    return {};
  }

  deleteItem(id) {
    if (localStorage.getItem("newPd")) {
      let getProduct = JSON.parse(localStorage.getItem("newPd"));
      let isPresent = getProduct.findIndex((val) => val.id == id);

      if (isPresent != -1) {
        getProduct.splice(
          getProduct.findIndex((val: any) => val.id == id),
          1
        );
        localStorage.setItem("newPd", JSON.stringify(getProduct));
      }
    }
    if (localStorage.getItem("API")) {
      let getProductAPI = JSON.parse(localStorage.getItem("API"));
      let isPresent = getProductAPI.findIndex((val) => val.id == id);

      if (isPresent != -1) {
        getProductAPI.splice(
          getProductAPI.findIndex((val: any) => val.id == id),
          1
        );
        localStorage.setItem("API", JSON.stringify(getProductAPI));
      }
    }

    return "deleted";
  }
  // deleting Weight
  deleteWeightItem(id) {
    let res = "Id Not found";
    if (localStorage.getItem("newPd")) {
      let getProduct = JSON.parse(localStorage.getItem("newPd"));
      let isPresent = getProduct.findIndex((val) => val.id == id);
      if (isPresent != -1) {
        getProduct.splice(
          getProduct.findIndex((val: any) => val.id == id),
          1
        );
        localStorage.setItem("newPd", JSON.stringify(getProduct));
        res = "deleted";
      }
      return res;
    }
  }

  // function to save histroy
  saveHistoryItems(newItem) {
    let newProdlist = [];
    if (localStorage.getItem("Histroy")) {
      newProdlist = JSON.parse(localStorage.getItem("Histroy"));
      newProdlist = [...newItem, ...newProdlist];
    } else {
      newProdlist = newItem;
    }
    localStorage.setItem("Histroy", JSON.stringify(newProdlist));
  }

  // deleting Histroy Item
  updateHistroyItem(id) {
    if (localStorage.getItem("Histroy")) {
      let getHistroyProduct = JSON.parse(localStorage.getItem("Histroy"));
      let isPresent = getHistroyProduct.findIndex((val) => val.id == id);
      if (isPresent != -1) {
        getHistroyProduct.splice(
          getHistroyProduct.findIndex((val: any) => val.id == id),
          1
        );
        localStorage.setItem("Histroy", JSON.stringify(getHistroyProduct));
        return "deleted";
      }
    }
  }
}
