import {Injectable, Inject} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationCancel} from '@angular/router';
import {isDevMode} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SESSION_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUserModel } from 'app/views/user/models/i-user.model';

@Injectable()
export class AuthGuard implements CanActivate {
  private isAuthenticated = false; // Set this value dynamically
  private userData = null;
  public returnUrl : string = null;

  public isMobile: boolean;

  supervisorRoleName : string = 'Supervisor';
  operatorRoleName : string = 'Operator';
  logisticsRoleName : string = 'Logistics';
  adminRoleName : string = 'Admin';
  observerRoleName : string = 'Observer';
  superUserRoleName: string = "SuperUser";

  @BlockUI() blockUI: NgBlockUI;

  static singletonInstance: AuthGuard;
  private usersSubject = new BehaviorSubject<IUserModel[]>([]);
  usersObservable = this.usersSubject.asObservable();
  constructor(
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: WebStorageService
    ) {
      //added this validation because authGuard was beeing called witha a new instance when router changes to otherModule
      //so this way, he keeps getting called in that otherModule but uses the same instance
      if (!AuthGuard.singletonInstance){
        AuthGuard.singletonInstance = this;
      }
      return AuthGuard.singletonInstance;

      //this.userData = storage.get("SARUserData");
  }
  getUsersObservable(): Observable<IUserModel[]> {
    return this.usersObservable;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isMobile) return false;

    this.redirectIfNeed(state);

    if (this.isAuthenticated) {
      return true;
    }
    if(isDevMode() && this.userData != null){

    }
    if(typeof(this.userData) != 'undefined' && this.userData != null){
      var d = new Date(this.userData.expiration);
      if(d.getTime() > new Date().getTime()){
        this.isAuthenticated = true;
        return true;
      }
    }
    this.returnUrl = state.url;
    this.logout();
    return false;
  }

  private redirectIfNeed(state: RouterStateSnapshot){
  }

  logout(){
    this.userData = null;
    this.isAuthenticated = false;
    this.storage.remove("SARUserData");
    this.storage.remove("SARAuthKey");
    let args = {};
    if(this.returnUrl != null && this.returnUrl != '' && this.returnUrl != '/dashboard'){
      args = { queryParams: { returnUrl: this.returnUrl } };
    }
    this.router.navigate(['/sessions/signin'], args);
  }

  clearUserData(){
    this.userData = null;
    this.isAuthenticated = false;
    this.storage.remove("SARUserData");
    this.storage.remove("SARAuthKey");
  }

  doLogin(data : any){
    this.userData = data;
    //this.storage.set("SARUserData", data);
    if(isDevMode()){

    }
    this.isAuthenticated = true;
    //this.blockUI.stop();
  }

  isSupervisor(){ return this.isInRole(this.supervisorRoleName); }
  isOperator(){ return this.isInRole(this.operatorRoleName); }
  isLogistics(){ return this.isInRole(this.logisticsRoleName); }
  isAdmin(){ return this.isInRole(this.adminRoleName) || this.isInRole(this.superUserRoleName); }
  isObserver(){ return this.isInRole(this.observerRoleName); }
  isSuperUser() { return this.isInRole(this.superUserRoleName); }

  isInRole(role : string) : boolean{
    if(this.userData == null) return false;
    if(this.userData?.roles?.length == 0) return false;

    //if role name is Admin check whether is a Superuser
    if(role == this.adminRoleName && this.isInRole(this.superUserRoleName)) return true;

    for(var i = 0; i < this.userData?.roles?.length; i++){
      if(this.userData?.roles[i].toLowerCase() == role.toLowerCase()){
        return true;
      }
    }
    return false;
  }

  getProperty(key : string){
    if(this.userData == null) return '';
    return this.userData[key];
  }

  setIsAuthenticated(isAuthenticaded: boolean) {
    this.isAuthenticated = isAuthenticaded;
  }
  getColor(userId : string){
    return "#eee";
  }

  isDelegate(): boolean{
    return this.userData && this.userData.isDelegate;
  }

}
