import { DatePipe } from "@angular/common";
import { FormGroup } from "@angular/forms";

export function getIndexBy(array: Array<{}>, { name, value }): number {
  for (let i = 0; i < array.length; i++) {
    if (array[i][name] === value) {
      return i;
    }
  }
  return -1;
}

//TODO: NEED TO START USING THIS AT THE OTHER COMPONENTS
export function getCurrentYear(sum: number) {
  return (new Date().getFullYear() + sum).toString();
}

/**
 * @param formGroup
 * @param debug
 * @returns emphasizes, with red coloring, if any required field from fromGroup was left unfilled
 */
export function markFormGroupTouched(
  formGroup: FormGroup,
  debug: boolean = false
) {
  if (!formGroup) return;
  if (!formGroup.controls) return;
  (<any>Object).values(formGroup.controls).forEach((control) => {
    control.markAsTouched();
    if (control.controls) {
      this.markFormGroupTouched(control);
    }
  });
}

/**
 * @param formGroup
 * @param debug
 * @returns emphasizes, with red coloring, if any required field from fromGroup was left unfilled
 */
export function isFormValid(formGroup: FormGroup, debug: boolean = false) {
  if (!formGroup) return;
  if (!formGroup.controls) return;
  (<any>Object).values(formGroup.controls).forEach((control) => {
    if (control.status != "VALID") {
      console.log(control);
    }
    if (control.controls) {
      this.isFormValid(control);
    }
  });
}

/**
 * @returns the array that contains the possible levels of severity
 */
export function getSeverityLevels(): any[] {
  return [
    { id: 1, description: "High" },
    { id: 2, description: "Mid" },
    { id: 3, description: "Low" },
  ];
}

/**
 * used to paint cells with color (GYR)
 * @param el
 * @param dataItem
 */
export function setParentClass(el: Element, dataItem) {
  if (dataItem == "yellow") {
    el.parentElement.classList.add("yellow");
  }
  if (dataItem == "green") {
    el.parentElement.classList.add("green");
  }
  if (dataItem == "red") {
    el.parentElement.classList.add("red");
  }
  if (dataItem == "tbd") {
    el.parentElement.classList.add("tbd");
  }
}

/**
 * @param letter
 * @returns the color of the GYR scheme based on the letter
 */
export function colorCode(letter) {
  let result;
  switch (letter) {
    case "G":
      result = "green";
      break;
    case "R":
      result = "red";
      break;
    default:
      result = "yellow";
      break;
  }
  return result;
}

/**
 *
 * @param date
 * @returns date in the designated format
 */
export function validateDate(date: string) {
  if (date) {
    const datePipe: DatePipe = new DatePipe("en-US");
    let formattedDate = datePipe.transform(date, "dd MMM yyyy");
    return formattedDate;
  }
}

/**
 * changes the array content based on old_index and new_index
 * @param arr
 * @param old_index
 * @param new_index
 * @returns the new array
 */
export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export function getMonth(number: number): string {
  switch (number) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    default:
      return "December";
  }
}

export function formatDecimals(value : number, decimalPlaces : number = 2) : number {
  return Number(Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces);
  //return Math.round((value + Number.EPSILON) * 1000) / 1000;
}

export function generateUniqueId() : string {
  let rand = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
  return rand.toString();
}

export function daylightSavingTime(date) : Date {
  if (!date) return null;
  var d = new Date(date);
  d.setTime(d.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
  return d;
}


