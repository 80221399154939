import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { daylightSavingTime } from "../helpers/utils";

@Injectable({
  providedIn: "root",
})
export class CustomSnackbarService {
  public snackbarSubject = new Subject<any>();
  public snackbarState = this.snackbarSubject.asObservable();
  private localStorageKey = "amw:messages";

  constructor() {}

  private getStoredMessages(): any[] {
    const storedMessages = localStorage.getItem(this.localStorageKey);
    const parsedMessages: any[] = storedMessages ? JSON.parse(storedMessages) : [];

    parsedMessages.sort((a, b) => {
      const dateA = new Date(a.datetime).getTime();
      const dateB = new Date(b.datetime).getTime();
      return dateB - dateA;
    });

    return parsedMessages;
  }

  private updateStoredMessages(messages: any): void {
    localStorage.setItem(this.localStorageKey, JSON.stringify(messages));
  }


  success(message: string, payload?: any) {
    this.write(message, 'success', payload)
  }

  warning(message: string, payload?: any) {
    this.write(message, 'warning', payload)
  }

  error(message: string, payload?: any) {
    this.write(message, 'danger', payload)
  }

  info(message: string) {
    this.write(message, 'info')
  }

  private write(message: string, type: string, payload?: any) {
    if(message == null || message == ''){
      return;
    }

    const newMessage = {
      message,
      type,
      payload: JSON.stringify(payload),
      datetime: this.getFormattedDateTime()
    };
    let storedMessages = this.getStoredMessages();
    storedMessages.push(newMessage);

    this.updateStoredMessages(storedMessages);

    this.snackbarSubject.next({
      ...newMessage,
      show: true,
      messages: storedMessages.slice(),
    });
  }

  show(message: string, type: string) {
    if(message == null || message == ''){
      return;
    }

    const newMessage = {
      message,
      type,
      datetime: this.getFormattedDateTime()
    };

    this.snackbarSubject.next({
      ...newMessage,
      show: true
    });
  }

  getMessages(): any[] {
    return this.getStoredMessages();
  }

  private getFormattedDateTime(): string {
    const now = daylightSavingTime(new Date());
    const formattedDateTime = now.toLocaleString("pt-PT", { timeZone: "UTC" });
    return formattedDateTime;
  }
}
